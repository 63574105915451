import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  theme: "light",
  speed: 12500,
  transition: "scroll",
  nav: ""
};

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_THEME": {
      return {
        ...state,
        theme: state.theme === "light" ? "dark" : "light"
      };
    }
    case "CHANGE_SPEED": {
      return {
        ...state,
        speed: action.newSpeed
      };
    }
    case "CHANGE_TRANSITION": {
      return {
        ...state,
        transition: action.newTransition
      };
    }
    case "CHANGE_NAV": {
      return {
        ...state,
        nav: action.nav
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

export default function GlobalContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}
